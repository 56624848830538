import { Student } from '@app/admin/model';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService, UtilService } from '@app/shared/services';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { InitStudentService } from '@app/student/services';
import { CalendarService } from '@app/student/services/calendar.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CryptoService } from '@app/shared/services';
import { Router } from '@angular/router';
import {
  CourseTypeCss,
  CourseTypeLabel,
  ModuleType,
  StatusClass,
  StatusClassCss,
  StatusClassLabel,
} from '@app/shared/enum';
import { default as swal } from 'sweetalert2';
import {
  CourseService,
  StudentClassesCourseService,
} from '@app/admin/services';
import { first, map } from 'rxjs/operators';
import { formatDate, Location } from '@angular/common';

import { CalendarOptions, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import Locale from '@fullcalendar/core/locales/pt-br';
import { AccountService } from '@app/auth/services';
import { ContentEpisode } from '@app/content/model';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { environment } from '@environments/environment';
import { User } from '@app/auth/model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('mdCalendarDetails') mdCalendarDetails: any;
  private student: Student;
  public dashboardUrl = '/student/home';
  public allowMeeting: boolean = false;

  public messageNoContentCalendar: string =
    'Validando informações do calendário... Aguarde!';

  public studentCourseClassId: any = 0;
  public courseType: any = 0;
  public studentClassesCourseExpectedDate: any;
  public studentClassesCourseReschedule = new Date();
  public studentClassesCourseExpectedTime: any;

  public user: User = new User();

  public calendarVisible: boolean = false;

  public statusClassList: any = [];
  public courseData: any;

  calendarPlugins = [dayGridPlugin]; // important!
  calendarEvents: Array<any> = [];
  closeResult: string;
  dataResult = false;
  public returnMessage = '';
  public calendar: any;
  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, bootstrapPlugin],
    themeSystem: 'bootstrap',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    //dateClick: this.handleDateClick.bind(this), // MUST ensure `this` context is maintained
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    initialView: 'listWeek',
    locale: Locale,
    //select: this.handleDateSelect.bind(this),
    eventClick: this.eventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventContent: function (info) {
      return { html: info.event.title };
    },
    titleFormat: {
      // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    },
    eventTimeFormat: {
      // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
  };

  private curr = new Date(); // get current date
  private first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
  private last = this.first + 6; // last day is the first day + 6
  private firstday = new Date(this.curr.setDate(this.first));
  private lastday = new Date(this.curr.setDate(this.last));

  calendarOptions2$ = this.calendarService
    .filter(0, this.firstday, this.lastday)
    .pipe(
      map((data) => ({
        ...this.calendarOptions,
        events: data,
        initialDate: this.firstday,
        initialView: 'listWeek',
      }))
    );

  eventDetails: any = {
    id: 0,
    title: '',
    start: new Date(),
    end: new Date(),
    duration: new Date(),
    course: '',
    teacher: '',
    student: '',
  };

  public episode: ContentEpisode;
  public serieId: number;
  public episodeId: number;

  private routeMeetingUrl = `${environment.urlLocal}/#/student/meeting`;

  constructor(
    private studentClassesCourseService: StudentClassesCourseService,
    private changeDetector: ChangeDetectorRef,
    private calendarService: CalendarService,
    private initStudent: InitStudentService,
    private courseService: CourseService,
    private utilService: UtilService,
    private cryptoService: CryptoService,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.initStudent.setConfigPage();
    this.student = this.initStudent.student;
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.getUserData();
    this.getStatusClassList();
    this.designConfig();
    setTimeout(() => {
      this.handleEvents(this.calendarEvents);
      setTimeout(() => {
        this.handleEvents(this.calendarEvents);
        this.designConfig();
      }, 1000);
    }, 1000);
  }

  private designConfig() {
    // Call the below function
    this.waitForElementToDisplay(
      '#fullCalendarStudent',
      function () {
        if (window.screen.width < 640) {
          var toolCalendar =
            document.getElementsByClassName('fc-header-toolbar');
          var calendarObj =
            document.getElementsByClassName('fc-scroller-liquid');
          var btnGroupFilter = document.getElementsByClassName('btn-group');
          var rangeDataText =
            document.getElementsByClassName('fc-toolbar-title');
          if (toolCalendar?.length > 0) {
            toolCalendar[0].classList.remove('row');
            toolCalendar[0].classList.add('row');
            if (calendarObj?.length > 0) {
              calendarObj[0].classList.remove('overflow-visible');
              calendarObj[0].classList.add('overflow-visible');
            }
            if (btnGroupFilter?.length > 0) {
              for (let i = 0; i < btnGroupFilter.length; i++) {
                if (btnGroupFilter[i].children.length == 4) {
                  btnGroupFilter[i].classList.remove('w-100');
                  btnGroupFilter[i].classList.add('w-100');
                }
              }
            }
            if (rangeDataText?.length > 0) {
              rangeDataText[0].classList.remove('text-center');
              rangeDataText[0].classList.add('text-center');
            }
            var toolCalendarDiv =
              document.getElementsByClassName('fc-toolbar-chunk');
            if (toolCalendarDiv) {
              for (var i = 0; i <= toolCalendarDiv.length; i++) {
                if (toolCalendarDiv[i] && toolCalendarDiv[i]?.classList) {
                  toolCalendarDiv[i].classList.remove('col-12');
                  toolCalendarDiv[i].classList.add('col-12');

                  if (i == 1) {
                    toolCalendarDiv[i].classList.remove('small');
                    toolCalendarDiv[i].classList.remove('mt-3');
                    toolCalendarDiv[i].classList.remove('mb-3');
                    toolCalendarDiv[i].classList.remove('text-primary');
                    toolCalendarDiv[i].classList.add('small');
                    toolCalendarDiv[i].classList.add('mt-3');
                    toolCalendarDiv[i].classList.add('mb-3');
                    toolCalendarDiv[i].classList.add('text-primary');
                  }
                }
              }
            }
          }
        }
      },
      1000,
      9000
    );
  }

  waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();
    (function loopSearch() {
      if (document.querySelector(selector) != null) {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
          callback();
        }, checkFrequencyInMs);
        return;
      } else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }

  getStatusClassList() {
    this.statusClassList = this.utilService.ToArray(
      StatusClass,
      StatusClassLabel
    );
    this.statusClassList.forEach((statusClass: any) => {
      statusClass.css = StatusClassCss.get(statusClass.value);
    });
  }

  private getUserData() {
    this.initStudent
      .getStudentUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.user = this.initStudent.user;
          this.student = response;
          this.loading.hideLoading();
        },
        (error: any) => {
          this.returnMessage = 'Erro ao carregar o calendário.';
          this.loading.hideLoading();
        }
      );
  }

  eventsSet(events: any) {
    console.log(events);
  }

  handleEvents(events: EventApi[]) {
    if (this.calendarComponent) {
      let calendarApi = this.calendarComponent.getApi();
      this.calendarOptions2$ = this.calendarService
        .filter(
          this.student?.id ?? 0,
          calendarApi.view.currentStart,
          calendarApi.view.currentEnd
        )
        .pipe(
          map((data) => ({
            ...this.calendarOptions,
            events: data,
            initialDate: calendarApi.view.currentStart,
            initialView: calendarApi.currentData.currentViewType,
          }))
        );
      setTimeout(() => {
        this.designConfig();
      }, 1000);
    }
    this.changeDetector.detectChanges();
  }

  eventClick(arg: any) {
    this.eventDetails.id = arg.event.id;
    this.eventDetails.title = arg.event.title;
    this.eventDetails.start = arg.event.start;
    this.eventDetails.end = arg.event.end;
    this.eventDetails.duration = arg.event.extendedProps.duration;
    this.eventDetails.course = arg.event.extendedProps.course;
    this.eventDetails.teacher = arg.event.extendedProps.teacher;
    this.eventDetails.teacherEmail = arg.event.extendedProps.teacherEmail;
    this.eventDetails.teacherPhone = arg.event.extendedProps.teacherPhone;
    this.eventDetails.student = arg.event.extendedProps.student;
    this.eventDetails.studentEmail = arg.event.extendedProps.studentEmail;
    this.eventDetails.studentPhone = arg.event.extendedProps.studentPhone;
    this.eventDetails.class = arg.event.extendedProps.class;
    this.eventDetails.status = arg.event.extendedProps.status;
    this.eventDetails.designStatusClass =
      arg.event.extendedProps.designStatusClass;

    this.eventDetails.description = arg.event.extendedProps.description;

    this.eventDetails.courseType = arg.event.extendedProps.courseType;
    this.eventDetails.courseTypeCss = arg.event.extendedProps.courseTypeCss;
    this.eventDetails.courseTypeStr = arg.event.extendedProps.courseTypeStr;

    this.eventDetails.confirmedClassByStudent =
      arg.event.extendedProps.confirmedClassByStudent;
    this.eventDetails.confirmedDateByStudent =
      arg.event.extendedProps.confirmedDateByStudent;
    this.eventDetails.confirmedClassByTeacher =
      arg.event.extendedProps.confirmedClassByTeacher;
    this.eventDetails.confirmedDateByTeacher =
      arg.event.extendedProps.confirmedDateByTeacher;
    this.eventDetails.canceledClass = arg.event.extendedProps.canceledClass;
    this.eventDetails.canceledDate = arg.event.extendedProps.canceledDate;

    this.eventDetails.episode = arg.event.extendedProps.episode;

    this.eventDetails.courseIdentifier =
      arg.event.extendedProps.courseIdentifier;
    this.eventDetails.classRoom = arg.event.extendedProps.classRoom;

    this.modalService.dismissAll();
    this.modalService
      .open(this.mdCalendarDetails, {
        backdrop: 'static',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            console.log('result = save');
            // this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onEventClick(event) {
    console.log(event);
    //alert('Clicked on date : ' + res.dateStr)
  }

  addEvent() {
    this.calendarEvents = this.calendarEvents.concat({
      title: 'event 2',
      date: '2019-04-02',
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  designStatusClass(status: StatusClass) {
    let statusClassCss = StatusClassCss.get(status);
    let statusClassStr = StatusClassLabel.get(status);
    return (
      "<span class='badge badge-pill w-100 font-weight-bold " +
      statusClassCss +
      "'>" +
      statusClassStr +
      '</span>'
    );
  }

  modifyTitle(eventIndex: number, newTitle: string) {
    let calendarEvents = this.calendarEvents.slice(); // a clone
    let singleEvent = Object.assign({}, calendarEvents[eventIndex]); // a clone
    singleEvent.title = newTitle;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents; // reassign the array
  }

  changedClass(
    studentClassesCourseId: any,
    statusClass: StatusClass,
    linkAoVivo: string = ''
  ) {
    this.dismissModal();
    this.loading.showLoading();
    this.studentClassesCourseService
      .changedClass(studentClassesCourseId, statusClass, ModuleType.Student, 0)
      .subscribe(
        (response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccess(statusClass, linkAoVivo);
            } else {
              this.changedClassFailed(statusClass);
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(statusClass);
        }
      );
  }

  changedClassSuccess(status: StatusClass, linkAoVivo: string = '') {
    if (status == StatusClass.Reschedule)
      return this.changedClassSuccessGlobal();

    swal({
      type: 'success',
      title: linkAoVivo
        ? `Presença registrada na aula com sucesso!`
        : `Atualização realizada com sucesso!`,
      html:
        linkAoVivo && !this.allowMeeting
          ? '<small>Caso a aba do seu navegador não abra automaticamente, ' +
            'clique no link abaixo ou copie e cole no seu navegador para entrar na sala!</small><br><br>' +
            '<a href="' +
            linkAoVivo +
            '" target="_blank">' +
            linkAoVivo +
            '</a><br>'
          : '',
      showConfirmButton: status != StatusClass.Unchecked,
      confirmButtonText:
        status != StatusClass.Unchecked ? 'Entrar na sala' : '',
      timer: status != StatusClass.Unchecked ? null : 3000,
    }).then((result: any) => {
      this.loading.showLoading();
      if (result.value === true && linkAoVivo) {
        window.open(linkAoVivo, '_blank');
        setTimeout(() => {
          this.loading.hideLoading();
          window.location.reload();
        }, 5000);
      } else {
        setTimeout(() => {
          this.handleEvents(null);
          this.loading.hideLoading();
        }, 1000);
      }
    });
  }

  changedClassSuccessGlobal() {
    swal({
      type: 'success',
      title: `Atualização realizada com sucesso!`,
      showConfirmButton: false,
      timer: 2200,
    }).then(() => {
      window.location.reload();
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível realizar a atualização!`,
      showConfirmButton: true,
    }).then(() => {
      //window.location.reload();
    });
  }

  openClassRoom(courseIdentifier: any, content: any) {
    if (courseIdentifier) {
      this.courseService
        .getByIdentifier(courseIdentifier)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.typeResponse == TypeResponse.Success) {
              this.courseData = JSON.stringify(response.data);
              this.modalService
                .open(content, {
                  backdrop: 'static',
                  size: 'lg',
                  windowClass: 'animated fade modal-backdrop-transparent',
                })
                .result.then(
                  (result) => {
                    this.closeResult = `Closed with: ${result}`;
                  },
                  (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(
                      reason
                    )}`;
                  }
                );
              this.returnMessage = '';
            } else {
              swal({
                type: 'warning',
                title: 'Problema na aula ao vivo',
                text: response.message,
                showConfirmButton: true,
              });
            }
          },
          (error: any) => {
            swal({
              type: 'error',
              title: 'Problema na aula ao vivo',
              text: 'Ocorreu um erro ao iniciar a aula ao vivo!',
              showConfirmButton: true,
            });
          }
        );
    } else {
      swal({
        type: 'warning',
        title: 'Problema na aula ao vivo',
        text: 'Não foi possível iniciar a aula ao vivo!',
        showConfirmButton: true,
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  getClassRoomUrl(classRoom: any): string {
    return `https://meet.jit.si/ec-class-room-${classRoom}`;
  }

  getRouteClassRoom(classRoom: any) {
    let classRoomCrypto = this.cryptoService.encrypt(classRoom);
    return `${this.routeMeetingUrl};classRoom=${classRoomCrypto}`;
  }

  checkClassRoomAccess(event: any) {
    let checkDate: Date;
    checkDate = new Date(event.start);
    checkDate.setHours(0, 0, 0, 0);

    let dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    let checkStatus = this.checkEditByStatus(event);

    return (
      this.initStudent.CheckHavePermission('EST1AL7AV44') &&
      checkStatus &&
      checkDate.toDateString() === dateNow.toDateString()
    );
  }

  checkEditByStatus(event: any) {
    return (
      event.status == StatusClass.ChangedTeacher ||
      event.status == StatusClass.Scheduled ||
      event.status == StatusClass.PartialConfirmed ||
      event.status == StatusClass.Reschedule ||
      event.status == StatusClass.Done
    );
  }

  checkCancelButton(event: any) {
    return event.status == StatusClass.Delayed;
  }

  checkRescheduleButton(event: any) {
    // Verifica se a data do evento está a mais de 3 horas da data atual.
    if (event.courseType == 1) {
      let eventStart: Date;
      eventStart = new Date(event.start);
      let dateNow = new Date();
      return (
        this.utilService.isMoreThanAnyHours(dateNow, eventStart, 3) &&
        (event.status == StatusClass.ChangedTeacher ||
          event.status == StatusClass.Scheduled)
      );
    }
    return (
      event.status == StatusClass.ChangedTeacher ||
      event.status == StatusClass.Scheduled
    );
  }

  getCourseTypeDesign(courseType: any) {
    let courseTypeCss = CourseTypeCss.get(courseType);
    let courseTypeStr = CourseTypeLabel.get(courseType);
    return {
      courseTypeStr,
      courseTypeCss,
    };
  }

  studentCourseClassReschedule(event: any, content: any) {
    if (event.status == StatusClass.Reschedule) {
      return this.rescheduleClassFailed();
    }

    this.studentCourseClassId = event.id;
    this.studentClassesCourseExpectedDate = event.start;
    this.courseType = event.courseType;
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'sm',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  rescheduleClassFailed() {
    swal({
      type: 'warning',
      title: `Não é possível reagendar uma aula que já foi reagendada!`,
      showConfirmButton: true,
    }).then(() => {
      this.dismissModal();
    });
  }

  rescheduleNotAllow(event: any) {
    swal({
      type: 'warning',
      title: 'Limite de remarcação excedido!',
      html: `Essa aula já passou do horário limite para remarcação 😔<br><small>Remarcação de aulas são permitidas com 3 horas de antecedência.</small>`,
      showConfirmButton: true,
    });
  }

  setValueTime(input: any) {
    this.studentClassesCourseExpectedTime = input.value;
  }

  setValueDate(input: any) {
    this.studentClassesCourseExpectedDate = input.value;
  }

  willChangeClassReschedule(
    willChangeClassReschedule: any,
    courseType: any
  ): void {
    this.modalService.dismissAll();
    if (courseType == 1) {
      return this.confirmChangeClassReschedule(courseType);
    }

    swal({
      title: 'Sugerir remarcação da aula',
      html:
        'Ao confirmar essa ação, será enviado uma notificação para o(a) professor(a) ' +
        'solicitando a remarcação da aula conforme proposto.' +
        '<br><strong>Confirma a notificação para o(a) professor(a)?</strong>',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, notificar professor(a)',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          this.confirmChangeClassReschedule(this.courseType);
        } else {
          this.loading.hideLoading();
          return;
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar cancelar a aula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
          });
        }
      }
    );
  }

  private confirmChangeClassReschedule(courseType: any) {
    this.dismissModal();
    this.loading.showLoading();
    let newDateStr = '0';

    if (this.courseType == 0) {
      let newDate = new Date(
        this.studentClassesCourseExpectedDate + 'GMT-0300'
      );
      newDate.setMinutes(this.studentClassesCourseExpectedTime.split(':')[1]);
      newDate.setHours(this.studentClassesCourseExpectedTime.split(':')[0]);
      newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
    }

    this.studentClassesCourseService
      .rescheduleSuggestion(this.studentCourseClassId, newDateStr)
      .subscribe(
        (response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccess(
                courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked
              );
            } else {
              this.changedClassFailed(
                courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked
              );
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(
            courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked
          );
        }
      );
  }

  checkConfirmClass(target: any): void {
    let link: string = '';
    swal({
      title: 'Confirmação da aula',
      html: 'Deseja confirmar a participação na aula ao vivo?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, confirmar minha presença',
      cancelButtonText: 'Não',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          link = this.getClassRoomUrl(target.classRoom);
          if (
            target.studentEmail &&
            this.initStudent.CheckHavePermission('EST1AL7MT35')
          ) {
            this.allowMeeting = true;
            link = this.getRouteClassRoom(target.classRoom);
          }
          this.changedClass(target.id, 1, link);
        } else {
          this.dismissModal();
          this.loading.hideLoading();
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title:
              'Erro ao tentar entrar na aula. Atualize a página e tente novamente!',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            //window.location.reload();
          });
        }
      }
    );
  }
}
